import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./about.module.scss"

const AboutPage = () => {
  return (
    <div>
      <Layout>
        <SEO title="About" />
        <h1 className={styles.title}>About me</h1>
        <p className={styles.body}>
          Jephte Colin is a Software Engineer at{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://trimagency.com/"
          >
            T R I M
          </a>
        
          , and an Open Source Contributor
          .
          <br/>
          He’s passionate about building efficient systems, and he’s done his
          best to positively influence the community by teaching at{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://codepath.org/"
          >
            Codepath
          </a>{" "}
          Bootcamps in Haiti.
        </p>
        <p className={styles.body}>
          He builds mobile apps for Android(Kotlin/Java) and iPhone(Swift),
          websites, APIs with NodeJS, Express and GraphQL.
          He has experience in a variety of programming languages and framework,
          notably JavaScript, HTML, CSS, React, React Native, NextJS, Gatsby.
        </p>
      </Layout>
    </div>
  )
}

export default AboutPage
